:root {
    --foreground-default: #08090a;
    --foreground-secondary: #41474d;
    --foreground-tertiary: #797c80;
    --foreground-quaternary: #f4faff;
    --foreground-light: #41474d;
  
    --background-default: #fff;
    --background-secondary: #a3b9cc;
    --background-tertiary: #5c7d99;
    --background-light: #ffffff;
  
    --primary-default: #008ecf;
    --primary-dark: #24b286;
    --primary-light: #b2ffe7;
  
    --error-default: #ef3e36;
    --error-dark: #800600;
    --error-light: #ffcecc; 
    --background-tertiary-shadow: 0 1px 3px 0 rgba(92, 125, 153, 0.5);
  }
   
  html, body { height: 100%; }
  body { 
    background: var(--background-default);
    margin: 0;
    font-family: var(--font-family-01);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;  
    .app-comp{
      background: var(--background-default);
    } 
  }
  p, span, a, button, input, em, label, div, select{ 
    font-family: var(--font-family-01);
    letter-spacing: 0.2px;
     
  }


  
  